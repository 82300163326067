

const fields = [
  {
    key: "email",
    label: "Email",
    type: "email"
  },
  {
    key: "role",
    label: "Role",
    type: "selection",
    selection: [
      {
        label: "User",
        value: "user"
      },
      {
        label: "Admin",
        value: "admin"
      },
    ]
  },
  {
    key: "message",
    notRequired: true,
    type: "multiline",
    rows: 2,
    rowsMax: 4,
  }
]

export default fields