import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1)
  },
  modal: {
    position: 'absolute',
    width: 400,
    top: theme.spacing()
    // backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    // height: "100vh",
    // width: "100vw",
    // marginTop: theme.spacing(8),
    // display: "flex",
    // outline: 0
  },
  modalContent: {
    // margin: "auto",
    // minWidth: 400,
    // minHeight: 300,

  },
  toolbarControls: {
    display: "flex",
    alignItems: "center"
  },
  toolbarCheckbox: {
    marginTop: theme.spacing(2)
  },
  hspacer: {
    flexGrow: 1
  },
  tableInvitationRow: {
    backgroundColor: 'aquamarine'
  }
}));
export default useStyles

