import {
  makeStyles,
  // Paper, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab";
import { useState } from "react";
// import { useState } from "react";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  // console.log({ order, orderBy })
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  console.log(`sorting`)
  console.log(JSON.stringify(stabilizedThis, null, 2))
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  console.log(JSON.stringify(stabilizedThis, null, 2))
  return stabilizedThis.map((el) => el[0]);
}
const useTableHeaderStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const TableHeader = ({
  columns = [],
  orderBy,
  order,
  onRequestSort,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes = useTableHeaderStyles()
  return (
    <TableHead>
      <TableRow >
        {
          columns.map(field => {
            const { hidden, name, id, isNumeric = false, disablePadding = false } = field
            if (hidden) {
              return null
            } else {
              const isBeingUsedToOrderData = orderBy === id
              const orderDirection = order
              return (
                <TableCell
                  key={id}
                  align={isNumeric ? 'right' : 'left'}
                  padding={disablePadding ? 'none' : 'default'}
                  sortDirection={isBeingUsedToOrderData ? orderDirection : false}
                >
                  <TableSortLabel
                    active={isBeingUsedToOrderData}
                    direction={orderDirection}
                    onClick={createSortHandler(id)}
                  >
                    {name}
                    {
                      isBeingUsedToOrderData
                        ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        )
                        : null
                    }
                  </TableSortLabel>
                  {/* {name} */}
                </TableCell>
              )
            }
          })
        }
      </TableRow>
    </TableHead>
  )
}

const AutoTableViewWithActionButtons = ({
  columns,
  data = [],
  name,
  noHeader = false,
  rowLinks = false,
  useFilters = false,
  useSort = false,
  defaultSort = null,
  className = null
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(defaultSort);
  // const [filters,setFilters] = useState(null)
  // const [sortColumn,setSortColumn] = useState(0)
  if (!columns || !columns[0]) {
    console.error('could not load table data')
    return <Skeleton variant="rect" height={500} />
  }
  const keyField = columns[0]
  // if(useSort){
  //   // need to add sort arrow buttons
  // }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  data = stableSort(data, getComparator(order, orderBy))
  const isClassNameDynamic = typeof className === 'function'
  console.log(data)
  return (
    <TableContainer>
      {name &&
        <Typography variant='h4'>{name}</Typography>
      }
      <Table className={classes.table} aria-label={name || "table"}>
        {
          !noHeader &&
          <TableHeader
            columns={columns}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleRequestSort}
          />
        }
        <TableBody>
          {data.map((rowData) => {
            console.log(className)
            let classes = className || null
            if (classes && isClassNameDynamic) {
              classes = className(rowData)
            }
            console.log({ classes, rowData, keyField })
            return (
              <TableRow key={rowData[keyField.id]} classes={{ root: classes }}>
                {
                  columns.map(field => {
                    if (field.hidden) {
                      return null
                    } else if (field.render) {
                      return <TableCell key={field.id}>{field.render(rowData)}</TableCell>
                    } else {
                      return <TableCell key={field.id}>{rowData[field.id]}</TableCell>
                    }
                  })
                }
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )

}

export default AutoTableViewWithActionButtons