import React from 'react';
import {
  Route,
} from 'react-router-dom';

import SignUpForm from 'components/SignUpForm';
import SignInForm from 'components/SignInForm';
import useStyles from './styles';
import { Container } from '@material-ui/core';
import ForgotPasswordForm from 'components/ForgotPasswordForm';

const SignInView = props => {
  const classes = useStyles()
  return (
    // <Card className={classes.root}>
    //   <CardContent className={classes.content}>
    <Container maxWidth="sm" className={classes.centerContentVertical}>
      <Route path='/login'>
        <SignInForm />
      </Route>
      <Route path='/signup'>
        <SignUpForm />
      </Route>
      <Route path='/forgotpassword'>
        <ForgotPasswordForm />
      </Route>
    </Container>
    //   </CardContent>
    // </Card>
  )
}
export default SignInView