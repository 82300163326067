import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
// import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  centerContentVertical: {
    marginTop: theme.spacing(5)
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      color: grey[400]
    },
    '&>:not(:first-child):not(:last-child)': {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    '&>hr': {
      flexGrow: 1
    }
  },
  input: {
    display: "flex",
  },
  centered: {
    display: "flex",
    justifyContent: "center"
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#ffffff",
  },
  spread: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  googleIcon: {
    position: "absolute",
    left: 0
  },
  logo: {
    height: "2em",
    marginRight: theme.spacing(1)
  },
  title: {
    alignItems: "center"
  },
  verticallyCenteredText: {
    alignItems: "center"
  }
}));
export default useStyles

