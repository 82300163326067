import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    paddingTop: "3rem",
    paddingBottom: "3rem",
  },
  container: {
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center'
  }
}));

export default useStyles;