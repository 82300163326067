import React, { useEffect, useMemo } from 'react';
import { Button, Divider, Grid, TextField, Typography } from '@material-ui/core';
import useStyles from './styles';
import MessageLink from 'components/MessageLink/MessageLink';
import clsx from 'clsx';
import animaetechLogo from '../../assets/logo.png'
import { Link, useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { useInvitation } from 'hooks/useInvitation';
import InvitationMessage from 'components/InvitationMessage';

const SignInForm = props => {
  const classes = useStyles()
  const { inviter, organization, recipient, isLoading, inviter_name, error } = useInvitation()
  const history = useHistory()
  // console.log(classes)
  const {
    submitHandler, email, password, handleChangeEmail, handleChangePassword, isNetworking, signInError } = props
  useEffect(() => {
    if (recipient && email !== recipient) {
      handleChangeEmail(recipient)
    }
  }, [email, handleChangeEmail, recipient])
  const onSubmit = e => {
    e.preventDefault()
    submitHandler()
  }
  const titleMessage = useMemo(() => {
    if (inviter && organization) {
      return "Organization Invitation"
    } else {
      return "Create User Account"
    }
  }, [inviter, organization])

  if (error) {
    // console.log("H")
    // console.error(error)
    history.push('/login')
    return <div />
  }
  if (isLoading) {
    return <div />
  }
  return (
    <Grid container spacing={3}  >
      <Grid item xs={12} className={clsx(classes.centered, classes.title)}>
        <img src={animaetechLogo} alt={"Animae Technologies Hub"} className={classes.logo} />
        <Typography variant="h6">Animae Technologies Hub</Typography>
      </Grid>
      <Grid item xs={12} className={clsx(classes.centered)}>
        <Typography variant="h4">{titleMessage}</Typography>
      </Grid>
      {
        inviter && organization &&
        <InvitationMessage inviter={inviter} inviter_name={inviter_name} organization={organization} />
      }
      <Grid item  >
        <form>
          <Grid container spacing={3}  >
            <Grid item xs={12} className={clsx(classes.centered, classes.input)}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="email"
                autoComplete="email"
                label="Email Address"
                value={email}
                onChange={e => handleChangeEmail(e.target.value)}
                required
                type="email"
                disabled={!!recipient}
              />
            </Grid>
            <Grid item xs={12} className={clsx(classes.centered, classes.input)}>
              <TextField
                size="small"
                fullWidth
                name="password"
                variant="outlined"
                autoComplete="password"
                label="Password"
                value={password}
                onChange={e => handleChangePassword(e.target.value)}
                required
                type="password"
              />
            </Grid>
            <Grid item xs={12} className={clsx(classes.centered, classes.verticallyCenteredText)}>
              <Link to="/forgotpassword">
                <Typography variant="body1">
                  Forgot your password?
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} className={clsx(classes.centered)}>
              <Button disabled={isNetworking} height={58} onClick={onSubmit} type="submit" color="primary" variant="contained" fullWidth>Log in</Button>
            </Grid>
            {
              signInError &&
              <Grid item xs={12} className={clsx(classes.centered)}>
                <Alert severity="error">{signInError}</Alert>
              </Grid>
            }
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div className={clsx(classes.content, classes.centered)}>
          <MessageLink premessage={`Don't have an account? `} linkmessage={`Sign Up`} to={`/signup`} />
        </div>
      </Grid>
    </Grid >
  )
}
export default SignInForm