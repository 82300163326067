import { Redirect, useParams } from "react-router-dom"
import PropTypes from 'prop-types'

const RedirectIfMissingResource = ({ redirectTo, resources, resourceID, paramKey, children, justReturnNothingIfItDoesNotExist = false }) => {
  const params = useParams()
  if (!resourceID && !paramKey) {
    console.error("Missing target resource id/parameter key")
    return <div />
  }
  let id = null
  if (resourceID) {
    id = resourceID
  } else if (paramKey && params) {
    id = params[paramKey]
  }
  if (!id) {
    console.error("Could not get target resource id")
    return <div />
  }

  let resourceExists = false
  if (Array.isArray(resources)) {
    if (resources.map(resource => resource.id).includes(id)) {
      resourceExists = true
    }
  } else if (resources[id]) {
    resourceExists = true
  }
  console.log({ resourceExists, resourceID, resources, paramKey, params })
  if (resourceExists) {
    return children
  } else {
    if (justReturnNothingIfItDoesNotExist) {
      return null
    } else {
      console.log("resource doesn't exist, redirecting...")
      return <Redirect to={redirectTo} />

    }
  }
}
RedirectIfMissingResource.propTypes = {
  redirectTo: PropTypes.string.isRequired,
  resources: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  resourceID: PropTypes.string,
  paramKey: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default RedirectIfMissingResource