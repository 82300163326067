import React, { useState } from 'react';
import { Button, Divider, Grid, TextField, Typography } from '@material-ui/core';
import useStyles from './styles';
import MessageLink from 'components/MessageLink/MessageLink';
import clsx from 'clsx';
import animaetechLogo from '../../assets/logo.png'
// import { Link } from 'react-router-dom';
// import useAuth from 'hooks/useAuth';
import auth from 'services/Authentication/firebase';
import { Link } from 'react-router-dom';

const ForgotPasswordForm = props => {
  const classes = useStyles()
  console.log(classes)
  const [email, setEmail] = useState('')
  // const {
  //   submitHandler, email, password, handleChangeEmail, handleChangePassword } = props
  const onSubmit = e => {
    e.preventDefault()
    auth.sendPasswordResetEmail(email)
  }
  return (
    <Grid container spacing={3}  >
      <Grid item xs={12} className={clsx(classes.centered, classes.title)}>
        <img src={animaetechLogo} alt={"Animae Technologies Hub"} className={classes.logo} />
        <Typography variant="h6">Animae Technologies Hub</Typography>
      </Grid>
      <Grid item xs={12} className={clsx(classes.centered)}>
        <Typography variant="h4">Forgot your password?</Typography>
      </Grid>
      <Grid item  >
        <form onSubmit={onSubmit}>
          <Grid container spacing={3}  >
            <Grid item xs={12} className={clsx(classes.centered, classes.input)}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="email"
                autoComplete="email"
                label="Email Address"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                type="email"
              />
            </Grid>
            <Grid item xs={12} className={clsx(classes.centered, classes.verticallyCenteredText)}>
              <Link to="/login">
                <Typography variant="body1">
                  Have your password?
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} className={clsx(classes.centered)}>
              <Button height={58} type="submit" color="primary" variant="contained" fullWidth>Send Password Reset Email</Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div className={clsx(classes.content, classes.centered)}>
          <MessageLink premessage={`Don't have an account? `} linkmessage={`Sign Up`} to={`/signup`} />
        </div>
      </Grid>
    </Grid >
  )
}
export default ForgotPasswordForm