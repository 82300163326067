import { Button, ButtonGroup, Card, CardContent, Typography } from "@material-ui/core"
import AutoForm from "components/AutoForm"
import AutoTableViewWithActionButtons from "components/AutoTableViewWithActionButtons"
import useStyles from "components/Navbar/Navbar.styles"
import useInvitations, { inviteUser } from "hooks/useInvitations"
import {
  useMemo,
  // useState
} from "react"
import { generateSSOID } from "services/SSO"
import fields from "./InviteUserForm.definitions"

const OrganisationInvitationsView = props => {
  const classes = useStyles()
  const { organisation } = props
  const organisationID = organisation.id
  console.log({ organisation, organisationID })
  const inviteObject = { organisation: organisationID }
  const invitations = useInvitations(inviteObject)

  const submitHandler = async data => {
    return inviteUser(data.email, organisationID, data.role === "admin", data.message)
  }
  let invitationData = []
  if (invitations.data && invitations.data.data) {
    invitationData = invitations.data.data
  }
  const columns = useMemo(() => {
    const actionRender = rowData => {
      const { appSSOURL } = rowData
      const baseURL = appSSOURL

      const handleClick = e => {
        e.preventDefault()
        generateSSOID().then(result => {
          const targetURL = `${baseURL}/${result.id}`
          window.location = targetURL
        }).catch(error => {
          console.error(error)
        })
      }

      return (
        <ButtonGroup>
          <Button size="small" onClick={handleClick}>
            Cancel Invitation
          </Button>
        </ButtonGroup>
      )
    }
    return [
      {
        id: "orgID",
        name: "Org ID",
        hidden: true
      },
      {
        id: 'email',
        name: "Email",
      },
      {
        id: 'actions',
        name: 'Actions',
        render: actionRender
      }
    ]
  },
    []
  )
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h4">Invitations</Typography>
      </CardContent>
      <CardContent>
        <AutoForm
          titleLabel="Invite user to organization"
          submitHandler={submitHandler} fieldData={fields} submitLabel={"Invite"}
        />
      </CardContent>
      <CardContent>
        <Typography variant="h6">Pending Invitations</Typography>
        <AutoTableViewWithActionButtons columns={columns}
          data={invitationData}
        />
      </CardContent>
    </Card>
  )
}

export default OrganisationInvitationsView