
import {
  AppBar, Button, Toolbar, Typography, Link as MaterialLink,
  // Breadcrumbs, IconButton 
} from '@material-ui/core';
import clsx from 'clsx';
import useAuth from 'hooks/useAuth';
// import useOrganisations from 'hooks/useOrganisations';
import {
  Link as RouterLink,
  //  Route, Switch, useParams
} from 'react-router-dom';
import useStyles from './Navbar.styles'
import { LogoImage } from 'assets'

// const BreadCrumbItem = ({ color = "inherit", to, typographyComponent = "h3", typographyVariant = "h6", noWrap = true, name, className }) => {
//   return (
//     <MaterialLink component={RouterLink} color={color} to={to} className={className}>
//       <Typography component={typographyComponent} variant={typographyVariant} color={color} noWrap={noWrap} >
//         {name}
//       </Typography>
//     </MaterialLink>
//   )
// }

// const DynamicBreadCrumbsWithOrganisationID = props => {
//   const { oID } = useParams()
//   const { current } = useOrganisations(oID)
//   let name = 'Organisation'
//   if (current && current.name) {
//     name = current.name
//   }
//   return (
//     <Switch>
//       <Route exact path={`/o/:oID/p`}>
//         <DynamicBreadCrumbs crumbs={[
//           { to: `/o/all`, name: "Organisations" },
//           { to: `/o/${oID}`, name: name },
//         ]} />
//       </Route>
//       <Route path={`/o/:oID/p/:pID`}>
//         <DynamicBreadCrumbs crumbs={[
//           { to: `/o/all`, name: "Organisations" },
//           { to: `/o/${oID}`, name: name },
//           { to: `/o${oID}/all`, name: "Projects" },
//         ]} />
//       </Route>
//     </Switch>
//   )
// }
// const DynamicBreadCrumbs = ({ crumbs = [], color = "secondary" }) => {
//   const classes = useStyles()

//   const { isFirstTimeLoading } = useAuth()
//   if (isFirstTimeLoading) {
//     crumbs = []
//   }
//   return (
//     <Breadcrumbs aria-label="breadcrumb">
//       <BreadCrumbItem to="/" color={color} name={"Animaetech Hub"} />
//       {
//         crumbs.map(crumb => (<BreadCrumbItem color={color} key={crumb.to} to={crumb.to} name={crumb.name} className={classes.BreadCrumbItem} />))
//       }
//     </Breadcrumbs>
//   )
// }

const Navbar = props => {
  const classes = useStyles()
  const { signOut, isAuthenticated } = useAuth()
  const handleSignOut = e => {
    e.preventDefault()
    signOut()
  }
  return (
    <>
      <AppBar position="absolute" className={clsx(classes.appBar)} color="secondary">
        <Toolbar className={classes.toolbar}>
          <MaterialLink className={classes.logoImageContainer} component={RouterLink} to="/">
            <img className={classes.logoImage} src={LogoImage} alt="Animae Technologies Limited" />
          </MaterialLink>
          <Typography variant="h3">Animaetech</Typography>
          {/* <Switch>
            <Route path={`/organisation/:oID`}>
              <DynamicBreadCrumbsWithOrganisationID />
            </Route>
            <Route path={`/organisation/:ending`}>
              <DynamicBreadCrumbs crumbs={[
                { to: `/organisation/all`, name: "Organisations" }
              ]} /> 
            </Route>
            <Route>
              <DynamicBreadCrumbs />
            </Route>
          </Switch> */}

          <div className={classes.toolbarAlignRightSpacer} />
          {
            isAuthenticated &&
            <Button onClick={handleSignOut} color="inherit">
              <Typography variant="body1">
                Sign Out
              </Typography>
            </Button>
          }

        </Toolbar>
      </AppBar>
    </>
  )
}

export default Navbar