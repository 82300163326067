import { poster } from "services/Data/api";

// should fail if user is not authed
export const generateSSOID = async (service = null) => {
  let targetURl = `/sso`;
  if (service) {
    targetURl += "/service/" + service;
  }
  let result = await poster(targetURl);
  if (result.data) {
    return result.data;
  }
  return result;
};
