import { Typography } from "@material-ui/core"
import {
  Link,
} from '@material-ui/core';
import {
  Link as RouterLink, useLocation,
} from 'react-router-dom';

const MessageLink = ({ premessage, linkmessage, to }) => {
  const { search } = useLocation()
  return (
    <div>
      <Typography variant="body2" color="textSecondary" component="p">
        {premessage}
        <Link component={RouterLink} to={to + `${search}`}>
          {linkmessage}
        </Link>
      </Typography>
    </div>
  )
}

export default MessageLink