// import {useAuth} from 'components/SignIn'
import { deleter, fetcher, puter } from "services/Data/api";
import useSWR, { mutate } from "swr";
import useAuth from "./useAuth";

export const removeUserFromOrganisation = (withUserID, fromOrganisationID) => {
  return deleter(`/organisations/${fromOrganisationID}/users/${withUserID}`);
};

export const setUserRoleInOrganisation = async (
  user,
  organisation,
  asAdmin
) => {
  return puter(`/organisations/${organisation}/users/${user}`, { asAdmin });
};

export const useOrganisationUsers = (organisationID) => {
  const { isAuthenticated } = useAuth();
  const users = useSWR(
    isAuthenticated && organisationID
      ? `/organisations/${organisationID}/users`
      : null,
    fetcher
  );
  const isLoading = !users.error && !users.data;
  const setUserRoleInOrganisationAndReload = async (userID, asAdmin) => {
    const result = await setUserRoleInOrganisation(
      userID,
      organisationID,
      asAdmin
    );
    users.mutate();
    return result;
  };
  const removeUserFromOrganisationAndReload = async (userID) => {
    const result = await removeUserFromOrganisation(userID, organisationID);
    users.mutate();
    mutate("/organisations");
    return result;
  };

  return {
    data: users.data,
    error: users.error,
    isLoading,
    setUserRoleInOrganisation: setUserRoleInOrganisationAndReload,
    removeUserFromOrganisation: removeUserFromOrganisationAndReload,
  };
};

export default useOrganisationUsers;
