import { Container, CssBaseline } from '@material-ui/core';
import Navbar from 'components/Navbar';
import { Helmet } from 'react-helmet';
import MainPageRouter from 'components/MainPageRouter/MainPageRouter';
import useStyles from './App.styles';
import RenderOnlyIfAuthenticationHasInitialised from 'components/RenderOnlyIfAuthenticationHasInitialised'
import React from 'react';
import { Route } from 'react-router-dom';
import Footer from 'components/Footer';

function App() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Helmet>
        <title>Animaetech Hub</title>
        <meta name="description" content="A hub for all your Animae Technologies Services" />
      </Helmet>
      <CssBaseline />
      <Route
        render={({ location }) => ['/signup', '/login', '/forgotpassword'].includes(location.pathname)
          ? null
          : <Navbar />
        }
      />

      <main className={classes.content}>
        <Route
          render={({ location }) => ['/signup', '/login', '/forgotpassword'].includes(location.pathname)
            ? null
            : (<>
              <div className={classes.appBarSpacer} />
            </>)
          }
        />
        <div className={classes.marginSpacer} />
        <Container className={classes.content} maxWidth='md'>
          <RenderOnlyIfAuthenticationHasInitialised placeholderType="progressBar" message="Please wait...">
            <MainPageRouter />
          </RenderOnlyIfAuthenticationHasInitialised>
        </Container>
        <Footer />
      </main>
    </div>
  );
}

export default App;
