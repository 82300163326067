const firebaseConfig = {
  apiKey: "AIzaSyAOVT1x-tIIdUtgINiS78Pq6CaKmRJESIw",
  authDomain: "animaetech-central-hub.firebaseapp.com",
  projectId: "animaetech-central-hub",
  storageBucket: "animaetech-central-hub.appspot.com",
  messagingSenderId: "392723795009",
  appId: "1:392723795009:web:2957b0eb87a80554df8ac0",
  measurementId: "G-ZJQ3B80WES"
};

export default firebaseConfig