import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#60a3ef",
      main: "#1e75bc",
      dark: "#004a8b",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#37302c",
      main: "#110700",
      dark: "#000000",
      contrastText: "#ffffff",
    },
  },
});
theme = responsiveFontSizes(theme);
export default theme;
