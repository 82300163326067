import OrganisationPageRouter from 'components/OrganisationPageRouter';
import {
  Redirect,
  Route, Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import SignInView from 'components/SignInView';
import RedirectIfUnauthenticatedRoute from 'components/RedirectIfUnauthenticatedRoute';
import RedirectIfAuthenticatedRoute from 'components/RedirectIfAuthenticatedRoute';
import React, { useEffect } from 'react';
import useServiceDefinitions from '../../hooks/useServiceDefinitions'
import useAuth from 'hooks/useAuth';
import { generateSSOID } from 'services/SSO';
import queryString from 'query-string'
import RedirectIfUnverifiedRoute from 'components/RedirectIfUnverifiedRoute';
import SendEmailVerificationForm from 'components/SendEmailVerificationForm';
import { FORGET_PASSWORD_ENDPOINT, ORGANISATIONS_SUB_PATH, SIGN_IN_ENDPOINT, SIGN_UP_ENDPOINT, VERIFY_EMAIL_ENDPOINT } from 'services/Constants/routes';
import AcceptInvitationPage from 'components/AcceptInvitationPage';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useRedirectAction = () => {
  let query = useQuery();
  const { user, token, isAuthenticated } = useAuth();
  const redirectURL = query.get('redirect_url')
  const originService = query.get("origin_service")
  const originServiceResource = query.get("origin_resource")
  const invitationID = query.get('accept')
  const history = useHistory()
  const { services } = useServiceDefinitions()
  // console.log(services)

  useEffect(() => {
    if (user && token && isAuthenticated) {
      console.log("redirecting")
      if (redirectURL) {
        // console.log(redirectURL)
        generateSSOID().then(result => {
          let targetURL = redirectURL + '/' + result.id
          if (originServiceResource) {
            targetURL += "?" + queryString.stringify({ cid: originServiceResource })
          }
          window.top.location = targetURL
        }).catch(error => {
          console.error(error)
        })
      } else if (originService) {
        // console.log({ originServiceResource, originService, services })
        // push to service url after creating an sso
        if (services && services.length) {
          const targetService = services.filter(service => service.service === originService)[0]
          generateSSOID().then(result => {
            let targetURL = `${targetService.appSSOURL}/${result.id}`
            let queries = {}
            if (originServiceResource) {
              queries.cid = originServiceResource
              targetURL = queryString.stringify({ cid: originServiceResource })
            }
            window.location = targetURL
          }).catch(error => {
            console.error(error)
          })
        }
      } else if (invitationID) {
        history.push(`/accept/${invitationID}`)
      }
    }
  }, [originService, originServiceResource, redirectURL, services, user, token, isAuthenticated, invitationID, history])
}


const VerifiedEmailOnlyRoutesRouter = props => {

  return (
    <Switch>
      <Route path={`${ORGANISATIONS_SUB_PATH}`}>
        <OrganisationPageRouter />
      </Route>
      <Route path={`/accept/:invitationID`}>
        <AcceptInvitationPage />
      </Route>
      <Route>
        <Redirect to={`${ORGANISATIONS_SUB_PATH}`} />
      </Route>
    </Switch>
  )
}

const AuthenticatedOnlyRoutesRouter = props => {
  return (
    <Switch>
      <Route path={`${VERIFY_EMAIL_ENDPOINT}`}>
        <SendEmailVerificationForm />
      </Route>
      <RedirectIfUnverifiedRoute redirectTo={`${VERIFY_EMAIL_ENDPOINT}`}>
        <VerifiedEmailOnlyRoutesRouter />
      </RedirectIfUnverifiedRoute>
    </Switch>
  )
}

const MainPageRouter = props => {
  const authentication = useAuth()
  useRedirectAction()
  console.log(authentication.finishedFirstLoad)
  if (!authentication.finishedFirstLoad) {
    return <div />
  }
  return (
    <Switch>
      <RedirectIfAuthenticatedRoute path={[SIGN_IN_ENDPOINT, SIGN_UP_ENDPOINT, FORGET_PASSWORD_ENDPOINT]} >
        <SignInView />
      </RedirectIfAuthenticatedRoute>
      <RedirectIfUnauthenticatedRoute redirectTo={`${SIGN_IN_ENDPOINT}`}>
        <AuthenticatedOnlyRoutesRouter />
      </RedirectIfUnauthenticatedRoute>
    </Switch>
  )
}

export default MainPageRouter