import { Grid, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  centered: {
    display: "flex",
    justifyContent: "center"
  },
}));
const InvitationMessage = props => {
  const { inviter, organization, inviter_name } = props
  const classes = useStyles()
  return (
    <Grid item xs={12} className={clsx(classes.centered)}>
      <Typography variant="subtitle2">
        {`${inviter_name || "A user "} (${inviter}) has invited you to join `}
        <b>{organization}</b>
        {` in Animae Portal. This portal will let you access Animae's services and view reports.`}
      </Typography>
    </Grid>
  )
}

export default InvitationMessage