import { fetcher } from "services/Data/api";
import useSWR from "swr";

/**
 * Currently loading data from database,
 * alternatively we could "bake" the data into the app
 * (since it shouldn't change)
 */
const useServiceDefinitions = (props) => {
  const services = useSWR("/services", fetcher, {
    dedupingInterval: 10 * 60 * 1000,
  });
  const plans = useSWR("/plans", fetcher, { dedupingInterval: 10 * 60 * 1000 });
  return {
    services: services.data,
    plans: plans.data,
  };
};

export default useServiceDefinitions;
