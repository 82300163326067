import { useInvitation } from "hooks/useInvitation";
import React, { useCallback, useEffect, useState } from "react";
import { signOut } from "services/Authentication/auth";
import { auth } from "services/Authentication/firebase";
import { poster } from "services/Data/api";
// import { poster } from "services/Data/api";
// import api from "services/Data/api";
// import api from "services/Data/api";
import AuthContext from "./AuthContext";
// import Cookies from "js-cookie";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userDataPresent, setUserDataPresent] = useState(false);
  const [claims, setClaims] = useState({});
  const [claimsPresent, setClaimsPresent] = useState(false);
  const [previousPage, setPreviousPage] = useState(null);
  const [token, setToken] = useState(null);
  const [autoToken, setAutoToken] = useState(true);
  const { recipient } = useInvitation();
  const [finishedFirstLoad, setFinishedFirstLoad] = useState(false);

  const signOutAndClearToken = useCallback(async () => {
    const signOutResponse = await signOut(token);
    setToken(null);
    return signOutResponse;
  }, [token]);

  useEffect(() => {
    let unregister = auth.onAuthStateChanged(async (userAuth) => {
      setUserDataPresent(true);
      if (userAuth) {
        // await api
        //   .post("/sso/test", {}, { withCredentials: true })
        //   .then((response) => {
        //     // const { data } = response;
        //     // const { refreshToken } = data;
        //     // Cookies.set("refreshToken", refreshToken);
        //     // Cookies.set("animaetech_account_refreshToken", refreshToken, {
        //     //   domain: "animaetech.com",
        //     //   expires: 1
        //     // });
        //     // api.post(`http://localhost:5001/animaetech-central-hub/asia-east2/api/ext/auth/token`, {}, { withCredentials: true }).then(response => {
        //     //   console.log(response)
        //     // })
        //     // api.
        //     // document.cookie = "";
        //   });
        // console.log("I have user auth now");
        if (recipient && userAuth.email !== recipient) {
          await signOutAndClearToken();
        }
        if (autoToken) {
          const response = await poster("/auth/token");
          setFinishedFirstLoad(true);
          if (response) {
            setToken(response.token);
          }
        }
        setUser(userAuth);
        userAuth
          .getIdTokenResult()
          .then((idTokenResult) => {
            setClaims(idTokenResult.claims);
          })
          .catch((error) => {
            console.error(error);
            setClaims(null);
          })
          .finally(() => {
            setClaimsPresent(true);
          });
      } else {
        setUser(null);
        setFinishedFirstLoad(true);
      }
    });
    return unregister;
  }, [autoToken, recipient, signOutAndClearToken]);

  const value = {
    user,
    claims,
    userDataPresent,
    claimsPresent,
    previousPage,
    setPreviousPage,
    setUserToken: setToken,
    token,
    autoToken,
    setAutoToken,
    signOutAndClearToken,
    finishedFirstLoad,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
