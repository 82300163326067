import React from "react";
import ReactDOM from "react-dom";
import App from "components/App/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import AuthProvider from "context/AuthProvider";
import "fontsource-roboto/500.css";
import { ThemeProvider } from "@material-ui/styles";
import theme from "theme";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
