export const ORGANISATIONS_SUB_PATH = "/organisations";
export const ORGANISATIONS_LIST_ENDPOINT = "/all";
export const ORGANISATIONS_CREATE_ENDPOINT = "/create";
export const ORGANISATION_LIST_USERS_ENDPOINT = "/users";
export const ORGANISATION_LIST_SERVICES_ENDPOINT = "/services";
export const ORGANISATION_LIST_INVITATIONS_ENDPOINT = "/invitations";
export const VERIFY_EMAIL_ENDPOINT = "/verify";
export const SIGN_UP_ENDPOINT = "/signup";
export const SIGN_IN_ENDPOINT = "/login";
export const FORGET_PASSWORD_ENDPOINT = "/forgotpassword";
