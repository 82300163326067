import { Card, CardContent } from '@material-ui/core';
import { useOrganisations } from 'hooks/useOrganisations';
import React from 'react';
import AutoForm from 'components/AutoForm'
import fields from './CreateOrganisationForm.definitions'

const CreateOrganisationForm = props => {
  const { onCreate } = props
  const { create } = useOrganisations()
  const submitHandler = data => {
    return create(data)
      .then(onCreate)
  }
  return (
    <Card>
      <CardContent>
        <AutoForm titleLabel="Create Organization" submitHandler={submitHandler} fieldData={fields} />
      </CardContent>
    </Card>
  )
}
export default CreateOrganisationForm