import { deleter, fetcher, poster, puter } from "services/Data/api";
import useSWR, { mutate } from "swr";
import useAuth from "./useAuth";

export const inviteUser = async (
  withEmail,
  toOrganisation,
  asAdmin = false,
  withMessage = null
) => {
  const invitationDTO = {
    orgID: toOrganisation,
    email: withEmail,
    asAdmin: asAdmin,
  };
  if (withMessage) {
    invitationDTO.message = withMessage;
  }
  return poster(`/invitations`, invitationDTO);
};

export const acceptInvitation = async (invitationID) => {
  return puter(`/invitations/${invitationID}`, { accept: true });
};

export const cancelInvitation = async (invitationID) => {
  return deleter(`/invitations/${invitationID}`);
};

export const useInvitations = ({ email = null, organisation = null } = {}) => {
  const { isAuthenticated } = useAuth();
  console.log({ email, organisation });
  let query = "";
  if (email || organisation) {
    query += "?";
  }
  if (email) {
    query += `email=${email}`;
  }
  if (email && organisation) {
    query += `&`;
  }
  if (organisation) {
    query += `organisation=${organisation}`;
  }

  const invitations = useSWR(
    isAuthenticated ? `/invitations${query}` : null,
    fetcher
  );

  const acceptInvitationAndReload = async (invitationID) => {
    let result = await acceptInvitation(invitationID);
    invitations.mutate();
    mutate("/organisations");
    return result;
  };
  const inviteUserAndReload = async (
    email,
    asAdmin = false,
    message = null
  ) => {
    const org = organisation;
    if (!org) {
      console.error("missing organsiation");
      return;
    }
    let result = await inviteUser(email, org, asAdmin, message);
    invitations.mutate();
    return result;
  };
  const cancelInvitationAndReload = async (invitationID) => {
    const result = await cancelInvitation(invitationID);
    invitations.mutate();
    return result;
  };

  return {
    data: invitations.data,
    error: invitations.error,
    isLoading: !invitations.error && !invitations.data,
    acceptInvitation: acceptInvitationAndReload,
    inviteUser: inviteUserAndReload,
    cancelInvitation: cancelInvitationAndReload,
  };
};

export default useInvitations;
