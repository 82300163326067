import {
  Button, ButtonGroup, Card, CardContent, LinearProgress,
  //  List, ListItem, ListItemText,
  Typography
} from "@material-ui/core"
import AutoTableViewWithActionButtons from "components/AutoTableViewWithActionButtons"
import useInvitations from "hooks/useInvitations"
import useOrganisations from "hooks/useOrganisations"
import { Link } from "react-router-dom"
import { ORGANISATIONS_SUB_PATH } from "services/Constants/routes"
import useStyles from "./styles"


const InvitationsTable = props => {
  const { data, error, isLoading, acceptInvitation } = useInvitations()
  const classes = useStyles()
  if (isLoading) {
    return <div>
      <LinearProgress />
    </div>
  }
  if (error) {
    console.error(error)
    return <div />
  }
  const invitationsData = data.data
  let tableData = invitationsData.map(invitation => {
    return {
      id: invitation.id,
      organisation: invitation.orgName,
      message: invitation.message,
      expires: new Date(invitation.expiry).toDateString()
    }
  })
  const actionRender = rowData => {
    const handleClick = e => {
      e.preventDefault()
      acceptInvitation(rowData.id).then(result => {
        console.log("Accepted Invitation")
        console.log(result)
      })
    }

    return (
      <ButtonGroup size="small" className={classes.acceptButton}>
        <Button onClick={handleClick}>
          Accept
        </Button>
      </ButtonGroup>
    )
  }
  if (tableData.length < 1) {
    return (<Typography variant="body1">You currently have no invitations</Typography>)
  }
  return (
    <AutoTableViewWithActionButtons columns={[
      {
        id: "id",
        name: "InvitationID",
        hidden: true
      },
      {
        id: 'organisation',
        name: "Organization",
      },
      {
        id: 'message',
        name: "Message"
      },
      {
        id: 'expires',
        name: "Expiry date"
      },
      {
        id: 'actions',
        name: 'Actions',
        render: actionRender
      },
    ]}
      data={tableData}
    />
  )
}

const OrganisationListCard = props => {
  const { data, error, isLoading } = useOrganisations()
  const classes = useStyles()
  if (isLoading) {
    return <div>
      <LinearProgress />
    </div>
  }
  if (error) {
    console.error(error)
    return <div />
  }
  const orgList = data

  const tableData = orgList.map(org => {
    return {
      id: org.id,
      name: org.name,
    }
  })

  const tableColumns = [
    {
      id: "id",
      name: "Organization ID",
      hidden: true,
    },
    {
      id: "name",
      name: "Organization",
    },
    {
      id: 'actions',
      name: "Actions",
      render: data => {
        return (
          <ButtonGroup>

            <Button component={Link} to={`${ORGANISATIONS_SUB_PATH}/${data.id}`}>
              View
            </Button>

          </ButtonGroup>
        )
      }
    }
  ]

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h4">Invitations</Typography>
          <InvitationsTable />

        </CardContent>
      </Card>
      <Card>

        <CardContent>
          <Typography variant="h4">Organizations</Typography>
          <Button className={classes.button} variant="outlined" component={Link} to={`create`}>Create Organization</Button>
          <AutoTableViewWithActionButtons columns={tableColumns} data={tableData} noHeader rowLinks noSeparators />
          {/* <List>
          <ListItem button component={Link} to={`create`}>
            <ListItemText primary={`Create Organisation`} />
          </ListItem>

          {
            orgList.map(org => {
              return (
                <ListItem button key={org.id} component={Link} to={`${org.id}`}>
                  <ListItemText primary={org.name} />
                </ListItem>
              )
            })
          }
        </List> */}
        </CardContent>
      </Card>
    </>
  )
}

export default OrganisationListCard