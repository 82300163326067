// import {useAuth} from 'components/SignIn'
import { fetcher, poster } from "services/Data/api";
import useSWR from "swr";
import useAuth from "./useAuth";

// export const removeUserFromOrganisation = (withUserID, fromOrganisationID) => {
//   return deleter(`/organisations/${fromOrganisationID}/users/${withUserID}`);
// };

export const createAPIKeyForOrganisation = async (organisation, asAdmin) => {
  return poster(`/api_keys`, { asAdmin, orgID: organisation });
};

export const useAPIKeys = (organisationID) => {
  const { isAuthenticated } = useAuth();
  const keys = useSWR(
    isAuthenticated && organisationID
      ? `/api_keys?orgID=${organisationID}`
      : null,
    fetcher
  );
  const isLoading = !keys.error && !keys.data;
  const createAPIKeyForOrganisationAndReload = async (asAdmin) => {
    const result = await createAPIKeyForOrganisation(organisationID, asAdmin);
    keys.mutate();
    return result;
  };

  return {
    data: keys.data,
    error: keys.error,
    isLoading,
    createAPIKeyForOrganisation: createAPIKeyForOrganisationAndReload,
  };
};

export default useAPIKeys;
