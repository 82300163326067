import { Button, ButtonGroup, Grid, makeStyles } from "@material-ui/core"
import clsx from "clsx";
import InvitationMessage from "components/InvitationMessage";
import useInvitations from "hooks/useInvitations";
import { useHistory, useParams } from "react-router"

const useStyles = makeStyles((theme) => ({
  acceptButton: {
    backgroundColor: 'aquamarine'
  },
  centered: {
    display: "flex",
    justifyContent: "center"
  },
}));
const AcceptInvitationPage = props => {
  const { invitationID } = useParams()
  const { data, error, isLoading, acceptInvitation } = useInvitations()
  const history = useHistory()
  const classes = useStyles()
  const handleAcceptInvitation = e => {
    acceptInvitation(invitationID).then(result => history.push('/organisation'))
  }
  if (isLoading) {
    return <div></div>
  }
  if (error) {
    history.push(`/organisation`)
    return <div />
  }
  const matchingInvitations = data.data.filter(invitation => invitation.id === invitationID)
  if (matchingInvitations.length < 1) {
    history.push('/organisation')
    return <div></div>
  }
  const currentInvitation = matchingInvitations[0]
  const organization = currentInvitation.orgName
  const inviter = currentInvitation.inviter
  const inviter_name = currentInvitation.inviter_name
  console.log({ data, matchingInvitations })
  return (
    <Grid container spacing={3}>
      {
        inviter && organization &&
        <InvitationMessage inviter={inviter} inviter_name={inviter_name} organization={organization} />
      }
      <Grid item xs={12} className={clsx(classes.centered)}>
        <ButtonGroup>
          <Button onClick={handleAcceptInvitation} className={classes.acceptButton}>
            Accept Invitation
          </Button>
        </ButtonGroup>
      </Grid>

    </Grid>
  )
}
export default AcceptInvitationPage