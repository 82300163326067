import { addProfileToUser } from "services/Data/user";
import { auth } from "./firebase";
// import { CENTRAL_HUB_API_BASE_EXT_PATH } from "services/Constants/environment";
import { callSignOutAPI } from "services/Data/api";
export const signIn = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password);
  // .catch((error) => {
  //   console.error("Error signing in with password and email", error);
  // });
};
export const signUp = (email, password, userData = {}) => {
  if (!userData.displayName) {
    throw new Error("Missing username");
  }
  return auth.createUserWithEmailAndPassword(email, password).then((result) => {
    result.user.sendEmailVerification();
    if (
      userData &&
      (userData.displayName || userData.photoURL || userData.phoneNumber)
    ) {
      return addProfileToUser(result.user.uid, userData);
    }
    return result;
  });
};
export const signOut = async (custom_jwt) => {
  await callSignOutAPI(custom_jwt);
  return auth.signOut();
};
var actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "https://account.animaetech.com/",
  // This must be true.
  handleCodeInApp: true,
};
export const verify = (email) => {
  return auth
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem("emailForSignIn", email);
      // ...
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
      console.error({ errorCode, errorMessage });
    });
};
// export const onSignInSignOut = auth.onAuthStateChanged
