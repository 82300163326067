import useAuth from 'hooks/useAuth';
import React from 'react';
import { useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

const RedirectIfUnauthenticatedRoute = (props) => {
  const { redirectTo } = props
  const { pathname } = useLocation()
  const { user, setPreviousPage } = useAuth()
  useEffect(()=>{
    if(!user){
      setPreviousPage(pathname)
    }
  })
  if(!user){
    const targetRoute = redirectTo || "/"
    return <Redirect to={targetRoute} />
  }

  return (
    <Route {...props} />
  );
}

export default RedirectIfUnauthenticatedRoute;