// import { useAuth } from 'components/SignIn'
import { LinearProgress } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import useAuth from 'hooks/useAuth'
import React from 'react'

const RenderOnlyIfAuthenticationHasInitialised = ({ children, placeholderType = null, message = "" }) => {
  const { isFirstTimeLoading } = useAuth()
  if (isFirstTimeLoading) {
    switch (placeholderType) {
      case "nothing":
      case null:
        return null
      case "skeleton":
        return <Skeleton variant="rect" height={350} />
      case "progressBar":
      default:
        return <div>
          <LinearProgress />
          {message}</div>


    }
  }

  return children
}

export default RenderOnlyIfAuthenticationHasInitialised