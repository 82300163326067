

const fields = [
  {
    key: "name",
    label: "Organization Name"
  },
  {
    key: "br",
    label: "Business Registration Number",
    notRequired: true,
  },
  {
    key: "address_1",
    label: "Address Line 1",
    notRequired: true,
  },
  {
    key: "address_2",
    label: "Address Line 2",
    notRequired: true,
  },
  {
    key: "postcode",
    label: "Postcode",
    type: 'number',
    notRequired: true,
  },
  {
    key: "city",
    label: "City",
    notRequired: true,
  },
  {
    key: "state",
    label: "State",
    notRequired: true,
  },
  {
    key: "country",
    label: "Country",
    notRequired: true,
  },
  {
    key: 'contact_number',
    label: "Contact Number",
    type: "tel",
    notRequired: true,
  },
  {
    key: 'contact_email',
    label: "Contact Email",
    type: "email",
    notRequired: true,
  },
  {
    key: 'industry',
    label: "Industry",
    notRequired: true,
  },
]

export default fields