import useAuth from 'hooks/useAuth';
import React from 'react';
import { useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

const RedirectIfUnverifiedRoute = (props) => {
  const { redirectTo } = props
  const { pathname } = useLocation()
  const { user, setPreviousPage, isEmailVerified } = useAuth()
  useEffect(() => {
    if (!user) {
      setPreviousPage(pathname)
    }
  })
  if (!isEmailVerified) {
    const targetRoute = redirectTo || "/"
    return <Redirect to={targetRoute} />
  }

  return (
    <Route {...props} />
  );
}

export default RedirectIfUnverifiedRoute;