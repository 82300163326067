import Axios from "axios";
import { signOut } from "services/Authentication/auth";
import { auth } from "services/Authentication/firebase";
import {
  CENTRAL_HUB_API_BASE_EXT_PATH,
  CENTRAL_HUB_API_BASE_PATH,
} from "services/Constants/environment";
import CustomException from "services/CustomException";

const api = Axios.create({
  baseURL: CENTRAL_HUB_API_BASE_PATH,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
const extAPI = Axios.create({
  baseURL: CENTRAL_HUB_API_BASE_EXT_PATH,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
api.interceptors.request.use(
  async (config) => {
    if (!auth.currentUser) {
      // throw new CustomException("Error, no auth");
    } else {
      const token = await auth.currentUser.getIdToken();
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const isAutoLogoutResponse = (error) => {
  return ["0012", "0041"].includes(error.code);
};

export const poster = (url, data) => {
  return api
    .post(url, data)
    .then((response) => {
      if (!response) {
        // console.error("Poster Error 01");
        throw new CustomException("Could not get response from API");
      }
      if (!response.data) {
        // console.error("Poster Error 02");
        throw new CustomException("Could not get response from API");
      }
      // if()
      const result = response.data;
      return result;
    })
    .catch((error) => {
      if (!error) {
        // console.log("custom error handling");
      } else if (!error.response) {
        // console.error(error);
      } else if (!error.response.data) {
        // console.error(error.response);
      } else {
        // console.error(error.response.data.message);
        if (isAutoLogoutResponse(error.response.data)) {
          signOut();
        }
      }
      return error;
    });
};
export const puter = (url, data) => {
  return api
    .put(url, data)
    .then((response) => {
      if (!response) {
        // console.error("Puter Error 01");
        throw new CustomException("Could not get response from API");
      }
      if (!response.data) {
        // console.error("Puter Error 02");
        throw new CustomException("Could not get response from API");
      }
      // if()
      const result = response.data;
      return result;
    })
    .catch((error) => {
      // console.log("custom error handling");
      // console.error(error.response.data.message);
      if (isAutoLogoutResponse(error.response.data)) {
        signOut();
      }
      return error;
    });
};
export const deleter = (url, data) => {
  return api
    .delete(url)
    .then((response) => {
      if (!response) {
        // console.error("Puter Error 01");
        throw new CustomException("Could not get response from API");
      }
      if (!response.data) {
        // console.error("Puter Error 02");
        throw new CustomException("Could not get response from API");
      }
      // if()
      const result = response.data;
      return result;
    })
    .catch((error) => {
      // console.log("custom error handling");
      // console.error(error.response.data.message);
      if (isAutoLogoutResponse(error.response.data)) {
        signOut();
      }
      return error;
    });
};

export const fetcher = async (url, token = null) => {
  let config = null;
  if (token) {
    config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
  }
  try {
    const apiResponse = await api.get(url, config);
    if (!apiResponse) {
      // console.error("Fetcher Error 01");
      throw new CustomException("Could not get response from API");
    }
    if (!apiResponse.data) {
      // console.error("Fetcher Error 02");
      throw new CustomException("Could not get response from API");
    }
    // if()`Bearer ${token}`;
    const result = apiResponse.data;
    return result;
  } catch (error) {
    if (isAutoLogoutResponse(error.response.data)) {
      signOut(token);
    }
    return error;
  }
};

export const callSignOutAPI = async (token) => {
  try {
    const apiResponse = await extAPI.get("/auth/logout", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (!apiResponse) {
      // console.error("Fetcher Error 01");
      throw new CustomException("Could not get response from API");
    }
    if (!apiResponse.data) {
      // console.error("Fetcher Error 02");
      throw new CustomException("Could not get response from API");
    }
    // if()`Bearer ${token}`;
    const result = apiResponse.data;
    return result;
  } catch (error) {
    console.error(error);
    throw new Error("Couldn't sign out user");
  }
};

export default api;
