// import { useAuth } from 'components/SignIn'
import useAuth from "hooks/useAuth";
import { fetcher, poster } from "services/Data/api";
import CustomException from "services/CustomException";
import useSWR from "swr";
// import {firestore} from '../firebase'

export const addProfileToUser = async (userID, profile) => {
  if (!userID || !profile) {
    throw new CustomException("Missing Parameters");
  }

  let target = {};
  let change = false;
  if (profile.displayName) {
    target.displayName = profile.displayName;
    change = true;
  }
  if (profile.photoURL) {
    target.photoURL = profile.photoURL;
    change = true;
  }
  if (profile.phoneNumber) {
    target.phoneNumber = profile.phoneNumber;
    change = true;
  }

  if (change) {
    return poster(`/auth/signup`, target);
    // await userRef.set(target,{merge:true})
  } else {
    throw new CustomException("Missing Parameters");
  }
};

export const useUserProfile = () => {
  const { data, error } = useSWR("/users/me", fetcher);
  const { user } = useAuth();

  const isLoading = !data && !error;
  const profile = getUserProfile(user, data);
  return { profile, error, isLoading };
};

const getUserProfile = (user, profile = {}) => {
  let result = {
    displayName: "",
    photoURL: "",
    phoneNumber: "",
    email: "",
  };
  if (!user) {
    return result;
  }
  result.email = user.email; // has to have email
  const profileKeys = ["displayName", "photoURL", "phoneNumber"];
  profileKeys.forEach((key) => (result[key] = user[key] || profile[key] || ""));
  return result;
};
