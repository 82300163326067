import firebase from 'firebase';
import { DEV_ONLY_APP_AUTH_EMU_PATH } from 'services/Constants/environment';
import firebaseConfig from './firebase.definitions'
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}
let auth = firebase.auth()
const authenticationEmulatorPath = DEV_ONLY_APP_AUTH_EMU_PATH
const isUsingEmulator = !!authenticationEmulatorPath
if (isUsingEmulator) {
  auth.useEmulator(authenticationEmulatorPath)
  console.warn("Using emulator @" + authenticationEmulatorPath)
}

export {
  auth,
}
export default auth