import {
  Button, ButtonGroup, Card, CardContent, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // Container,
  FormControlLabel, FormGroup,
  IconButton,
  // IconButton,
  // Grid,
  MenuItem,
  // Modal, 
  TextField,
  Tooltip,
  // Tooltip, 
  Typography
} from "@material-ui/core"
import AutoForm from "components/AutoForm"
import AutoTableViewWithActionButtons from "components/AutoTableViewWithActionButtons"
import useStyles from "./styles"
import useInvitations from "hooks/useInvitations"
import useOrganisationUsers from "hooks/useOrganisationUsers"
import useAPIKeys from "hooks/useAPIKeys"
import { useMemo, useState } from "react"
import fields from "./InviteUserForm.definitions"
import CloseIcon from '@material-ui/icons/Close';
// import FilterListIcon from '@material-ui/icons/FilterList';
// import clsx from "clsx"
function copyToClipboard(text) {
  var dummy = document.createElement("textarea");
  // to avoid breaking orgain page when copying more words
  // cant copy when adding below this code
  // dummy.style.display = 'none'
  document.body.appendChild(dummy);
  //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}
const OrganisationUsersView = props => {
  const classes = useStyles()
  const { organisation } = props
  const [showPopup, setShowPopup] = useState(false)
  const [showCreateKeyPopup, setShowCreateKeyPopup] = useState(false)
  const [doingSomeNetworkThing, setDoingSomeNetworkThing] = useState(false)
  // console.log(organisation)
  const organisationID = organisation.id
  const users = useOrganisationUsers(organisationID)
  const invitations = useInvitations({ organisation: organisationID })
  const keys = useAPIKeys(organisationID)
  const [showUsers, setShowUsers] = useState(true)
  const [showInvitations, setShowInvitations] = useState(true)
  const [showKeys, setShowKeys] = useState(false)
  const [formData, setFormData] = useState({})
  const [keyFormData, setKeyFormData] = useState({})
  const [emailFilter, setEmailFilter] = useState("")

  const submitKeyHandler = async data => {
    setDoingSomeNetworkThing(true)
    setShowCreateKeyPopup(false)
    const result = await keys.createAPIKeyForOrganisation(data.role === "admin")
    // const result = await invitations.inviteUser(data.email, data.role === "admin", data.message)
    setDoingSomeNetworkThing(false)
    return result
  }
  const submitHandler = async data => {
    setDoingSomeNetworkThing(true)
    setShowPopup(false)
    const result = await invitations.inviteUser(data.email, data.role === "admin", data.message)
    setDoingSomeNetworkThing(false)
    return result
  }
  const changeRoleHandler = async (userID, newRole) => {
    const asAdmin = newRole === "Admin"
    // do set user role call here
    setDoingSomeNetworkThing(true)
    const result = await users.setUserRoleInOrganisation(userID, asAdmin)
    setDoingSomeNetworkThing(false)
    return result
  }
  const handleRemoveUser = async (userID) => {
    setDoingSomeNetworkThing(true)
    const result = await users.removeUserFromOrganisation(userID)
    setDoingSomeNetworkThing(false)
    return result
  }
  const handleCancelInvite = async (inviteID) => {
    setDoingSomeNetworkThing(true)
    const result = await invitations.cancelInvitation(inviteID)
    setDoingSomeNetworkThing(false)
    return result
  }
  const tableColumns = [
    {
      id: "id",
      name: "User ID",
      hidden: true
    },
    {
      id: "email",
      name: "Email",
    },
    {
      id: "isAdmin",
      name: "Role",
      render: data => {
        switch (data.status) {
          case "invitation":
            return (
              <Typography variant="body1">
                Invited as {data.isAdmin ? "an " : "as a "}<b>{data.isAdmin ? "ADMIN" : "USER"}</b>
              </Typography>
            )
          case "user":
            const role = data.isAdmin ? "Admin" : "User"
            return (
              <TextField
                disabled={doingSomeNetworkThing}
                select value={role} onChange={e => {
                  const newRole = e.target.value
                  const userID = data.id
                  changeRoleHandler(userID, newRole)
                }}>
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="User">User</MenuItem>
              </TextField>
            )
          case "key":
            return (
              <Typography variant="body1">
                {data.isAdmin ? "ADMIN" : "USER"}
              </Typography>
            )
          default:
            return null

        }
      }
    },
    {
      id: "status",
      name: "Status",
      hidden: true
    },
    {
      id: "actions",
      name: "Actions",
      render: data => {
        switch (data.status) {
          case "invitation":
            return (
              <ButtonGroup>
                <Button
                  disabled={doingSomeNetworkThing}
                  onClick={e => {
                    //do remove user from org call here
                    const inviteID = data.id
                    handleCancelInvite(inviteID)
                  }}>
                  Cancel Invitation
                </Button>
              </ButtonGroup>
            )
          case "user":
            return (
              <ButtonGroup>
                <Button onClick={e => {
                  const userID = data.id
                  handleRemoveUser(userID)
                }} disabled={doingSomeNetworkThing}>
                  Remove User
                </Button>
              </ButtonGroup>
            )
          case "key":
            return (
              <ButtonGroup>
                <Button onClick={e => {
                  const token = data.token
                  copyToClipboard(token)
                }} disabled={doingSomeNetworkThing}>
                  Copy Key
                </Button>
              </ButtonGroup>
            )
          default:
            return null
        }
      }
    }
  ]
  let tableData = useMemo(() => {

    let data = []
    if (users.data && users.data.success && showUsers) {
      const processedUsers = users.data.data.map(user => {
        return {
          ...user,
          status: "user"
        }
      })
      data = [...data, ...processedUsers]
    }
    if (invitations.data && invitations.data.success && showInvitations) {
      const processedInvitations = invitations.data.data.map(invitation => {
        return {
          ...invitation,
          status: "invitation",
          isAdmin: invitation.asAdmin,
        }
      })
      data = [...data, ...processedInvitations]
    }

    if (keys.data && keys.data.success && showKeys) {
      console.log(keys.data.data)
      const processedKeys = keys.data.keys.map(key => {
        return {
          ...key,
          status: "key",
          email: "API Key",
        }
      })
      data = [...data, ...processedKeys]
    }


    if (emailFilter) {
      data = data.filter(rowData => {
        return rowData.email.includes(emailFilter)
      })
    }
    return data
  }, [emailFilter, invitations.data, keys.data, showInvitations, showKeys, showUsers, users.data])
  console.log(tableData)
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h4">Users</Typography>
      </CardContent>
      <CardContent>
        <Button
          variant="outlined"
          onClick={e => setShowPopup(true)}
          disabled={doingSomeNetworkThing}>
          {doingSomeNetworkThing ? "Inviting..." : "Invite User"}
        </Button>
        <Button
          variant="outlined"
          onClick={e => setShowCreateKeyPopup(true)}
          disabled={doingSomeNetworkThing}>
          {doingSomeNetworkThing ? "Creating..." : "Create API Key"}
        </Button>
      </CardContent>
      <Dialog onClose={e => setShowPopup(false)} aria-labelledby="simple-dialog-title" open={showPopup}>
        <DialogTitle id="simple-dialog-title">Invite User</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the email of the user you wish to invite, and select their role.</DialogContentText>

          <AutoForm
            // titleLabel="Invite user to organisation"
            // submitHandler={submitHandler} 
            fieldData={fields}
            hideButton
            onChange={data => setFormData(data)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={e => setShowPopup(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={e => {
            e.preventDefault()
            setShowPopup(false)
            submitHandler(formData)
          }} color="primary">
            Invite User
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={e => setShowCreateKeyPopup(false)} aria-labelledby="simple-dialog-title" open={showCreateKeyPopup}>
        <DialogTitle>Create API Key</DialogTitle>
        <DialogContent>
          <DialogContentText>Please select a permission level for the key.</DialogContentText>
          <AutoForm
            fieldData={fields}
            hideButton
            onChange={data => setKeyFormData(data)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={e => setShowCreateKeyPopup(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={e => {
            e.preventDefault()
            setShowCreateKeyPopup(false)
            submitKeyHandler(keyFormData)
          }} color="primary">
            Create Key
          </Button>
        </DialogActions>
      </Dialog>
      <CardContent>
        <Typography variant="h6">Users</Typography>
        <FormGroup row className={classes.toolbarControls}>
          <FormControlLabel
            className={classes.toolbarCheckbox}
            control={<Checkbox checked={showUsers} onChange={e => setShowUsers(e.target.checked)} />}
            label="Users"
          />
          <FormControlLabel
            className={classes.toolbarCheckbox}
            control={<Checkbox checked={showInvitations} onChange={e => setShowInvitations(e.target.checked)} />}
            label="Invitations"
          />
          <FormControlLabel
            className={classes.toolbarCheckbox}
            control={<Checkbox checked={showKeys} onChange={e => setShowKeys(e.target.checked)} />}
            label="API Keys"
          />
          <div className={classes.hspacer} />
          <TextField label="Filter by Email" value={emailFilter} onChange={e => setEmailFilter(e.target.value)} />
          <Tooltip title="Clear Filter"
            className={classes.toolbarCheckbox}>
            <IconButton aria-label="clear filter" onClick={e => setEmailFilter("")} size="small" >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </FormGroup>
        <AutoTableViewWithActionButtons
          columns={tableColumns}
          data={tableData}
          defaultSort={'status'}
          className={data => {
            if (data.status === "invitation") {
              return classes.tableInvitationRow
            } else {
              return classes.tableUserRow
            }
          }}
        />
      </CardContent>
    </Card>
  )
}

export default OrganisationUsersView