import React, { useEffect, useState } from 'react';
import {
  Button,
  // Divider, 
  Grid,
  // TextField, 
  Typography
} from '@material-ui/core';
import useStyles from './styles';
// import MessageLink from 'components/MessageLink/MessageLink';
import clsx from 'clsx';
import animaetechLogo from '../../assets/logo.png'
import useAuth from 'hooks/useAuth';
import { Redirect } from 'react-router-dom';

const SendEmailVerificationForm = props => {
  const classes = useStyles()
  const { user, isEmailVerified, reload } = useAuth()
  const [isNetworking, setIsNetworking] = useState(false)
  const [refreshUser, setRefreshUser] = useState(false)
  useEffect(() => {
    const timer = setInterval(() => {
      setRefreshUser(true)
    }, 5000)
    return () => clearInterval(timer)
  }, [reload])
  useEffect(() => {
    if (refreshUser && user) {
      user.reload()
      setRefreshUser(false)
    }
  }, [refreshUser, user])
  const handleSendVerification = async () => {
    setIsNetworking(true)
    const result = await user.sendEmailVerification()
    setIsNetworking(false)
    return result
  }
  const sendVerification = e => {
    e.preventDefault()
    handleSendVerification()
  }
  console.log(isEmailVerified)
  if (isEmailVerified) {
    return (
      <div>Your email has already been verified
        <Redirect to="/" />
      </div>
    )
  }
  return (
    <Grid container spacing={3}  >
      <Grid item xs={12} className={clsx(classes.centered, classes.title)}>
        <img src={animaetechLogo} alt={"Animae Technologies Hub"} className={classes.logo} />
        <Typography variant="h6">Animae Technologies Hub</Typography>
      </Grid>
      <Grid item xs={12} className={clsx(classes.centered)}>
        <Typography variant="h4">Please verify your email to use our services</Typography>
      </Grid>
      <Grid item xs={12} >
        <form onClick={sendVerification}>
          <Grid container spacing={3} className={clsx(classes.centered)}  >
            <Grid item className={clsx(classes.centered)}>
              <Button disabled={isNetworking} height={58} type="submit" color="primary" variant="contained" fullWidth>Send Verification Email</Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid >
  )
}
export default SendEmailVerificationForm