// import {useAuth} from 'components/SignIn'
import { fetcher } from "services/Data/api";
import { createOrganisation } from "services/Data/organisations";
import useSWR from "swr";
import useAuth from "./useAuth";

export const useOrganisations = (currentID = null) => {
  const { isAuthenticated, claims } = useAuth();
  const { data, error, mutate } = useSWR(
    isAuthenticated ? "/organisations" : null,
    fetcher
  );
  if (error) {
    console.log("e2");
    console.log(error);
  }

  let create = (organisationData) => {
    if (!claims || !claims.user_id) {
      return null;
    }
    return createOrganisation(claims, organisationData).then((result) => {
      const data = result.data || null;
      const id = result.id || null;
      console.log({ id, data, result });
      if (id && data) {
        mutate((orgList) => {
          return [...orgList, { id, ...data }];
        });
      }
      return result;
    });
  };
  let current = null;
  if (currentID && data) {
    try {
      current = data.filter((org) => org.id === currentID)[0];
    } catch (error) {
      console.error(error);
    }
  }
  const isLoading = !error && !data;

  return {
    data,
    error,
    isLoading,
    create,
    current,
  };
};

export default useOrganisations;
