// import { useAuth } from 'components/Auth';
import { Redirect, Route, useLocation } from 'react-router-dom';
import React from 'react';
import useAuth from 'hooks/useAuth';


export const RedirectIfAuthenticatedRoute = props => {
  const { redirectTo } = props
  const { isAuthenticated, previousPage } = useAuth()
  const { search } = useLocation()
  // console.log({ isAuthenticated, search, redirectTo })
  if (isAuthenticated && !search) {
    // console.log("HI")
    const targetRoute = redirectTo || previousPage || "/"
    return <Redirect to={targetRoute + search} />
  }

  return <Route {...props} />
}

export default RedirectIfAuthenticatedRoute