import { useLocation } from "react-router";
import { fetcher } from "services/Data/api";
import useSWR from "swr";

export function useInvitation({ invitationID = null } = {}) {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  invitationID = invitationID || urlSearchParams.get("accept");
  const invitation = useSWR(
    invitationID ? `/invitations/${invitationID}` : null,
    fetcher
  );
  const invitationData = invitation.data || {};
  const {
    inviter = null,
    organization = null,
    recipient = null,
    inviter_name = null,
  } = invitationData.data || {};
  const isLoading = !invitation.data && !invitation.error && invitationID;
  const invitationExists =
    !isLoading && invitation.data && invitation.data.success;
  // console.log(invitation);
  let error = invitation.error;
  if (
    invitation.data?.response?.data?.message ===
    "Invitation Document doesn't exist"
  ) {
    error = "invalid-invitation";
  }
  return {
    isLoading,
    error,
    inviter,
    organization,
    recipient,
    invitationExists,
    inviter_name,
  };
}
