import { Container, Typography } from "@material-ui/core"
import useStyles from "./styles"

const Footer = props => {
  const classes = useStyles()
  const year = new Date().getFullYear()
  const copyrightText = `Copyright © 2014-${year} Animae Technologies Limited. All Rights Reserved.`
  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        <Typography variant="body2">
          {copyrightText}
        </Typography>
      </Container>
    </footer>
  )
}

export default Footer