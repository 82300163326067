import { poster } from "services/Data/api";
import CustomException from "services/CustomException";

export const createOrganisation = async (userID, organisationData) => {
  const { name } = organisationData;
  if (!name || !userID) {
    throw new CustomException("Missing required parameters");
  }
  const result = await poster("/organisations", organisationData);
  // if (result) {
  //   return result.data;
  // }
  return result;
};

export default createOrganisation;
