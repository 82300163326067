

const fields = [
  {
    key: "email",
    label: "Email",
    type: "email"
  },
  {
    key: "role",
    label: "Role",
    type: "selection",
    selection: [
      {
        label: "Admin",
        value: "admin"
      },
      {
        label: "User",
        value: "user"
      }
    ]
  },
  {
    key: "message"
  }
]

export default fields