import useAuth from 'hooks/useAuth'
import React, { useState } from 'react'
import { poster } from 'services/Data/api'
import SignInForm from './SignInForm'

const SignInFormContainer = props => {

  const { signIn, setUserToken, setAutoToken } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [keepLoggedIn, setKeepLoggedIn] = useState(false)
  const [isNetworking, setIsNetworking] = useState(false)
  const [signInError, setSignInError] = useState('')

  const submitHandler = async data => {
    setIsNetworking(true)
    setSignInError('')
    setAutoToken(false)
    let firebaseSignInResult = null
    let APISignInResult = null
    try {
      firebaseSignInResult = await signIn(email, password)
      if (!firebaseSignInResult) {
        throw new Error({ message: "Could not find your email" })
      }
      APISignInResult = await poster("/auth/signin")
      console.log("got sign in");
      console.log(APISignInResult);
      if (!APISignInResult.token) {
        throw APISignInResult
      }
      setUserToken(APISignInResult.token)
      setAutoToken(true)
    } catch (error) {
      console.error(error)
      if (error && error.message) {
        if (error.code && error.code === 'auth/user-not-found') {
          setSignInError("We could not find your email. Please sign up first.")
        } else if (error.code && error.code === 'auth/wrong-password') {
          setSignInError("Sorry, you entered an incorrect password")

        } else {
          setSignInError(error.message)
        }
      } else {
        setSignInError("Sorry, we could not sign you in at this time. Please try again later.")
      }
    }
    setIsNetworking(false)
    console.log({ firebaseSignInResult, signInError, APISignInResult })
    return firebaseSignInResult
  }
  return (
    <SignInForm
      submitHandler={submitHandler}
      email={email}
      handleChangeEmail={setEmail}
      password={password}
      handleChangePassword={setPassword}
      keepLoggedIn={keepLoggedIn}
      handleChangeKeepLoggedIn={setKeepLoggedIn}
      isNetworking={isNetworking}
      signInError={signInError}
    />
  )
}

export default SignInFormContainer