import { LinearProgress } from '@material-ui/core';
import CreateOrganisationForm from 'components/CreateOrganisationForm';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import useOrganisations from 'hooks/useOrganisations';
import OrganisationListCard from 'components/OrganisationListCard';
import OrganisationDetailsCard from 'components/OrganisationDetailsCard';
import RedirectIfMissingResource from 'components/RedirectIfMissingResource';
import React from 'react';
import OrganisationUsersView from 'components/OrganisationUsersView/OrganisationUsersView';
import ActiveServicesView from 'components/ActiveServicesView';
import { ORGANISATIONS_CREATE_ENDPOINT, ORGANISATIONS_LIST_ENDPOINT, ORGANISATION_LIST_INVITATIONS_ENDPOINT, ORGANISATION_LIST_SERVICES_ENDPOINT, ORGANISATION_LIST_USERS_ENDPOINT } from 'services/Constants/routes';
import OrganisationInvitationsView from 'components/OrganisationInvitationsView';

const SpecificOrganisationRoute = props => {
  const { url, path } = useRouteMatch()
  const { organisationID } = useParams()
  const { redirectTo } = props
  const organisations = useOrganisations(organisationID)
  if (organisations.isLoading) {
    return <LinearProgress />
  }
  if (organisations.error) {
    console.error(organisations.error)
    return <div>Error loading organizations</div>
  }
  console.log({ url, path, organisations, organisationID })

  return (
    <RedirectIfMissingResource resources={organisations.data} resourceID={organisationID} redirectTo={redirectTo}>
      <OrganisationDetailsCard />
      <Switch>
        <Route exact path={`${path}${ORGANISATION_LIST_USERS_ENDPOINT}`}>
          <OrganisationUsersView organisation={organisations.current} />
        </Route>
        <Route exact path={[`${path}${ORGANISATION_LIST_SERVICES_ENDPOINT}`]}>
          <ActiveServicesView />
        </Route>
        <Route exact path={[`${path}${ORGANISATION_LIST_INVITATIONS_ENDPOINT}`]}>
          <OrganisationInvitationsView organisation={organisations.current} />
        </Route>
        <Route>
          <Redirect to={`${url}${ORGANISATION_LIST_SERVICES_ENDPOINT}`} />
        </Route>
      </Switch>
    </RedirectIfMissingResource>
  )
}

const OrganisationPageRouter = props => {
  const { data, error, isLoading } = useOrganisations()
  const { path, url } = useRouteMatch()
  const history = useHistory()
  if (error) {
    return <div>Error loading data</div>
  }
  if (isLoading) {
    return <div><LinearProgress />Loading data...</div>
  }
  const organisations = data
  const handleCreate = result => {
    // console.log(result)
    const newOrganisationPage = `${url}/${result.id}`
    history.push(newOrganisationPage)
    return result
  }
  console.log({ organisations, path, ORGANISATIONS_LIST_ENDPOINT, ORGANISATIONS_CREATE_ENDPOINT })
  return (
    <Switch>
      <Route exact path={`${path}${ORGANISATIONS_LIST_ENDPOINT}`}>
        <OrganisationListCard />
      </Route>
      <Route exact path={`${path}${ORGANISATIONS_CREATE_ENDPOINT}`}>
        <CreateOrganisationForm onCreate={handleCreate} />
      </Route>
      <Route path={`${path}/:organisationID`}>
        <SpecificOrganisationRoute redirectTo={`${url}${ORGANISATIONS_LIST_ENDPOINT}`} />
      </Route>
      <Route>
        <Redirect to={`${url}${ORGANISATIONS_LIST_ENDPOINT}`} />
      </Route>
    </Switch>
  )
}

export default OrganisationPageRouter