
import { Button, Card, CardActions, CardContent, LinearProgress, Typography } from "@material-ui/core"
import useStyles from "./styles"
import useOrganisations from "hooks/useOrganisations"
import { Link, useParams, useRouteMatch } from "react-router-dom"
import React, {
  // useMemo
} from "react"
// import useProjects from "hooks/useProjects"
import { Skeleton } from "@material-ui/lab"
import useOrganisationUsers from "hooks/useOrganisationUsers"
import { ORGANISATION_LIST_INVITATIONS_ENDPOINT, ORGANISATION_LIST_SERVICES_ENDPOINT, ORGANISATION_LIST_USERS_ENDPOINT } from "services/Constants/routes"
// import useAPIKeys from "hooks/useOrganisationAPIKeys"
// import useInvitations from "hooks/useInvitations"

const InfoButton = ({ isLoading, total, link = "", name, singular }) => {
  if (isLoading) {
    return <Skeleton variant="text" width={93} height={30} />
  }
  if (total < 1) {
    return <Button disabled>0 {name}</Button>
  }
  if (total === 1 && singular) {
    name = singular
  }
  return (
    <Button size="small" component={Link} to={link}>
      {total} {name}
    </Button>
  )
}

// const ProjectsInfoButton = ({ organisationID, link }) => {
//   const projects = useProjects({ organisationID })
//   return <InfoButton isLoading={projects.isLoading} total={projects?.data?.length} link={link} name="projects" singular="project" />
// }
// const CostsInfoButton = ({ organisationID, link }) => {
//   return <InfoButton name="billing" link={link} />
// }
const ServicesInfoButton = ({ link }) => {
  return <InfoButton name="services" link={link} />
}
const UsersInfoButton = ({ organisationID, link }) => {
  const users = useOrganisationUsers(organisationID)
  return <InfoButton total={users?.data?.length} name="users" link={link} singular="user" />
}
// const KeysInfoButton = ({ organisationID, link }) => {
//   const users = useAPIKeys(organisationID)
//   return <InfoButton total={users?.data?.length} name="api keys" link={link} singular="api key" />
// }
// const InvitationsInfoButton = ({ organisationID, link }) => {
//   const invitations = useInvitations({ organisation: organisationID })
//   return <InfoButton total={invitations?.data?.length} name="invitations" link={link} singular="invitation" />
// }

const OrganisationDetailsCard = props => {
  const classes = useStyles()
  const { organisationID } = useParams()
  const { error, isLoading, current } = useOrganisations(organisationID)
  const { url } = useRouteMatch()
  const links = {
    listProjects: `${url}/all`,
    listUsers: `${url}${ORGANISATION_LIST_USERS_ENDPOINT}`,
    listCosts: `${url}/costs`,
    listServices: `${url}${ORGANISATION_LIST_SERVICES_ENDPOINT}`,
    listInvitations: `${url}${ORGANISATION_LIST_INVITATIONS_ENDPOINT}`,
    listKeys: `${url}/keys`,
  }
  const {
    // listProjects,
    // listCosts, 
    listServices,
    listUsers,
    // listInvitations
  } = links
  if (isLoading) {
    return <div>
      <LinearProgress />
    </div>
  }
  if (error) {
    console.error(error)
    return <div />
  }
  if (!current) {
    console.error("where's my org")
    return <div />
  }
  const name = current && current.name
  const capitalisedName = name.charAt(0).toUpperCase() + name.slice(1)
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h4">{capitalisedName}</Typography>
        <Typography variant="subtitle2">Organization</Typography>
        {
          current.description &&
          <Typography variant="p">{current.description}</Typography>
        }
      </CardContent>
      <CardActions>
        <ServicesInfoButton link={listServices} />
        {/* <ProjectsInfoButton organisationID={organisationID} link={listProjects} /> */}
        <UsersInfoButton organisationID={organisationID} link={listUsers} />
        {/* <KeysInfoButton organisationID={organisationID} link={listUsers} /> */}
        {/* <InvitationsInfoButton organisationID={organisationID} link={listInvitations} /> */}
        {/* <CostsInfoButton link={listCosts} /> */}
      </CardActions>
    </Card>
  )
}

export default OrganisationDetailsCard