import { AuthContext } from "context/AuthContext";
import { useContext } from "react";
import { signIn, signUp } from "services/Authentication/auth";

export const useAuth = () => {
  const {
    user,
    claims,
    userDataPresent,
    claimsPresent,
    previousPage,
    setPreviousPage,
    setUserToken,
    token,
    setAutoToken,
    autoToken,
    signOutAndClearToken,
    finishedFirstLoad,
  } = useContext(AuthContext);

  const isAuthenticated = userDataPresent && user && token;
  const isFirstTimeLoading = !userDataPresent;
  const isFirstTimeLoadingClaims = !claimsPresent;
  const isEmailVerified = user && user.emailVerified;

  return {
    user,
    isEmailVerified,
    setUserToken,
    token,
    signIn,
    signUp,
    signOut: signOutAndClearToken,
    setAutoToken,
    autoToken,
    claims,
    userDataPresent,
    isAuthenticated,
    isFirstTimeLoading,
    isFirstTimeLoadingClaims,
    previousPage,
    setPreviousPage,
    finishedFirstLoad,
  };
};

export default useAuth;
