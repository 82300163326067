
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    maxHeight: 64,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    margin: 0,
    marginLeft: -theme.spacing(1)
  },
  toolbarAlignRightSpacer: {
    flexGrow: 1
  },
  logoImage: {
    height: 'auto',
    width: 'auto',
    maxHeight: 64 - theme.spacing(1),
  },
  logoImageContainer: {
    maxHeight: 64,
    padding: theme.spacing(0.5)
  }
  // BreadCrumbItem: {
  // maxWidth: 120,
  // whiteSpace: 'nowrap',
  // overflow: 'hidden',
  // textOverflow: 'ellipsis'
  // }
}));

export default useStyles