import {
  // Button, ButtonGroup, 
  Card,
  CardActionArea,
  // CardActions, 
  CardContent, CardMedia, Grid, makeStyles, Typography
} from "@material-ui/core"
// import AutoTableViewWithActionButtons from "components/AutoTableViewWithActionButtons"
import useServiceDefinitions from "hooks/useServiceDefinitions"
// import {
//   useMemo,
//   //  useState 
// } from "react"
// import { useHistory } from "react-router"
import { generateSSOID } from "services/SSO"
import useStyles from "./styles"

const useStylesForCard = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

function MediaCard(props) {
  const classes = useStylesForCard();
  const { imgSrc, imgTitle, title, description, linkURL } = props
  // const history = useHistory()
  const handleClick = e => {
    e.preventDefault()
    generateSSOID().then(result => {
      const targetURL = `${linkURL}/${result.id}`
      window.location = targetURL
    }).catch(error => {
      console.error(error)
    })
  }
  return (
    <Card className={classes.root}>
      <CardActionArea onClick={handleClick}>
        <CardMedia
          className={classes.media}
          image={imgSrc || ""}
          title={imgTitle || title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
    </Card>
  );
}


const ActiveServicesView = ({ title = "Services" }) => {
  const classes = useStyles()
  const { services } = useServiceDefinitions()
  return (
    <Card className={classes.card}>
      {title &&
        <CardContent>
          <Typography variant="h4">{title}</Typography>
        </CardContent>
      }
      <CardContent>
        <Grid container spacing={3}>
          {services.map(service => {
            return (
              <Grid key={service.id} item xs>
                <MediaCard
                  title={service.name}
                  imgSrc={service.image}
                  description={service.description}
                  linkURL={service.appSSOURL}
                />
              </Grid>
            )
          })}
        </Grid>
      </CardContent>
    </Card>
  )
}
export default ActiveServicesView