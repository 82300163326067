import React, { useEffect, useMemo, useState } from 'react';
// import AutoForm from 'components/AutoForm/AutoForm.js';
import useAuth from 'hooks/useAuth';
// import fields from './SignUpForm.definitions';
import MessageLink from 'components/MessageLink';
import { Button, Divider, Grid, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import animaetechLogo from '../../assets/logo.png'
import { Alert } from '@material-ui/lab';
import { useInvitation } from 'hooks/useInvitation';
import { useHistory } from 'react-router';
import InvitationMessage from 'components/InvitationMessage';
// import { Link } from 'react-router-dom';

const SignUpForm = props => {
  const auth = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [confirmPasswordEdited, setConfirmPasswordEdited] = useState(false)
  // const [photoURL, setPhotoURL] = useState('')
  const [isNetworking, setIsNetworking] = useState(false)
  const [signUpErrorMessage, setSignUpErrorMessage] = useState('')
  const { inviter, organization, recipient, isLoading, inviter_name, error } = useInvitation()
  const history = useHistory()
  const titleMessage = useMemo(() => {
    if (inviter && organization) {
      return "Organization Invitation"
    } else {
      return "Create User Account"
    }
  }, [inviter, organization])
  useEffect(() => {
    if (recipient && email !== recipient) {
      setEmail(recipient)
    }
  }, [email, recipient])
  const { signUp } = auth
  const submitHandler = async data => {
    setIsNetworking(true)
    setSignUpErrorMessage('')
    let result = null
    try {
      result = await signUp(data.email, data.password, data)
    } catch (error) {
      console.log(error)
      let errorMessage = error.message
      if (error.code === "auth/email-already-in-use" && recipient) {
        errorMessage = "You already have an account, please login instead"
      }
      setSignUpErrorMessage(errorMessage)
    }
    setIsNetworking(false)
    console.log(result)
    return result
  }
  // if query has an accept
  const showError = confirmPasswordEdited && (password !== confirmPassword)
  const classes = useStyles()
  if (error) {
    console.error(error)
    history.push('/signup')
    return <div />
  }
  if (isLoading) {
    return <div />
  }
  return (
    <Grid container spacing={3}  >
      <Grid item xs={12} className={clsx(classes.centered, classes.title)}>
        <img src={animaetechLogo} alt={"Animae Technologies Hub"} className={classes.logo} />
        <Typography variant="h6">Animae Technologies Hub</Typography>
      </Grid>
      <Grid item xs={12} className={clsx(classes.centered)}>
        <Typography variant="h4">{titleMessage}</Typography>
      </Grid>
      {
        inviter && organization &&
        <InvitationMessage inviter={inviter} inviter_name={inviter_name} organization={organization} />
      }
      <Grid item  >
        <form>
          <Grid container spacing={3}  >
            <Grid item xs={12} className={clsx(classes.centered, classes.input)}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="email"
                autoComplete="email"
                label="Email Address"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                type="email"
                disabled={!!recipient}
              />
            </Grid>
            <Grid item xs={12} className={clsx(classes.centered, classes.input)}>
              <TextField
                size="small"
                fullWidth
                name="password"
                variant="outlined"
                autoComplete="password"
                label="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
                type="password"
              />
            </Grid>
            <Grid item xs={12} className={clsx(classes.centered, classes.input)}>
              <TextField
                size="small"
                fullWidth
                name="password"
                variant="outlined"
                autoComplete="password"
                label="Confirm Password"
                value={confirmPassword}
                onChange={e => {
                  setConfirmPasswordEdited(true)
                  setConfirmPassword(e.target.value)
                }}
                error={showError}
                helperText={showError ? "Both passwords must be the same." : ""}
                required
                type="password"
              />
            </Grid>
            <Grid item xs={12} className={clsx(classes.centered, classes.input)}>
              <TextField
                size="small"
                fullWidth
                name="displayName"
                variant="outlined"
                autoComplete="name"
                label="Display Name"
                value={displayName}
                onChange={e => setDisplayName(e.target.value)}
                required
                type="text"
              />
            </Grid>
            <Grid item xs={12} className={clsx(classes.centered, classes.input)}>
              <TextField
                size="small"
                fullWidth
                name="phone"
                variant="outlined"
                autoComplete="phone"
                label="Phone Number"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                // required
                type="text"
              />
            </Grid>
            <Grid item xs={12} className={clsx(classes.centered)}>
              <Button height={58}
                disabled={isNetworking || showError}
                onClick={e => {
                  e.preventDefault()
                  submitHandler({ email, password, displayName, phoneNumber })
                }}
                type="submit" color="primary" variant="contained" fullWidth>Create Account</Button>
            </Grid>
            {
              signUpErrorMessage &&
              <Grid item xs={12} className={clsx(classes.centered)}>
                <Alert severity="error">{signUpErrorMessage}</Alert>
              </Grid>
            }
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div className={clsx(classes.content, classes.centered)}>
          <MessageLink premessage={`Already have an account? `} linkmessage={`Log In`} to={`/login`} />
        </div>
      </Grid>
    </Grid >
  )
  // return (
  //   <>
  //     <AutoForm titleLabel="Create user account" submitHandler={submitHandler} fieldData={fields} submitLabel="Create Account" />
  //     <MessageLink premessage={`Already have an account? `} linkmessage={`Log In`} to={`/login`} />
  //     {/* <div className={classes.content}>
  //     </div> */}
  //   </>
  // )
}
export default SignUpForm